// This file configures the initialization of Sentry on the browser.
// The config you add here will be used whenever a page is visited.
// https://docs.sentry.io/platforms/javascript/guides/nextjs/
;globalThis["_sentryRewritesTunnelPath"] = undefined;globalThis["SENTRY_RELEASE"] = {"id":"zI1_VdWYhgYaSQHz8nQSP"};globalThis["_sentryBasePath"] = undefined;globalThis["_sentryRewriteFramesAssetPrefixPath"] = "/salon";import * as Sentry from '@sentry/nextjs'

import { isProduction } from '~/common/Env'

const SENTRY_DSN = process.env.SENTRY_DSN || process.env.NEXT_PUBLIC_SENTRY_DSN

if (isProduction()) {
  Sentry.init({
    dsn: SENTRY_DSN || 'https://46134a756ce34be3af540d30cd079050@o171351.ingest.sentry.io/4504881893015552',
    // Adjust this value in production, or use tracesSampler for greater control
    tracesSampleRate: 0.25,
    // r.DomainData is from 202306.1.0/otBannerSdk which is not impacting user neither we can control
    ignoreErrors: [
      'Cancel rendering route',
      'r.DomainData',
      /Can't find variable: _AutofillCallbackHandler/,
      /\[RestClient\] Error with Data: \{"errors":\[\{"message":"User not found"\}\]\}/,
      /undefined is not an object \(evaluating 'a\.L'\)/,
      /\?\(webkit-masked-url:\/\/hidden\/\)/,
      /otBannerSdk/,
      'so.createConsentTxn(scripttemplates/202306.1.0/otBannerSdk)',
      /Non-Error promise rejection captured/,
    ],
    replaysSessionSampleRate: 0.1,
    replaysOnErrorSampleRate: 0.1,
    integrations: [Sentry.replayIntegration()],
    // ...
    // Note: if you want to override the automatic release value, do not set a
    // `release` value here - use the environment variable `SENTRY_RELEASE`, so
    // that it will also get attached to your source maps
  })
}
